.virtual-keyboard {
    display: flex;
    height: 250px;

    .btn {
        margin-bottom: 0;
        margin-top: 0;
    }

    .keyboard {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 85%;

        .keyboard-row {
            flex-grow: 1;
            display: flex;

            .keyboard-key-wrapper {
                flex-basis: 0;
                min-width: 0;
                text-align: center;

                button {
                    width: calc(100% - 2px);
                    height: calc(100% - 2px);
                    padding: 0;
                    overflow: hidden;
                }
            }
        }
    }

    .mouse {
        width: 15%;
        display: flex;

        button {
            width: 100%;
            height: 100%;
            padding: 0;
        }

        &-nav-buttons {
            width: 10%;

            > div:nth-child(1), > div:nth-child(4) {
                height: 35%;
            }

            > button:nth-child(2), > button:nth-child(3) {
                height: 15%;
                display: block;
                border-radius: 0.25rem 0 0 0.25rem;
            }
        }

        &-body {
            width: 90%;

            > div:first-child {
                height: 35%;
                display: flex;

                > div:nth-child(1) {
                    width: 40%;

                    button {
                        border-radius: 60% 0 0 0;
                    }
                }

                > div:nth-child(2) {
                    width: 20%;

                    button {
                        display: block;
                    }

                    > button:nth-child(1), > button:nth-child(3)  {
                        height: 25%;
                    }

                    > button:nth-child(2) {
                        height: 50%;
                    }
                }

                > div:nth-child(3) {
                    width: 40%;

                    button {
                        border-radius: 0 60% 0 0;
                    }
                }
            }

            > div:last-child {
                height: 65%;
                border: $primary 1px solid;
                border-radius: 0 0 40% 40%;
            }
        }
    }
}